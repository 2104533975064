import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../constants/services";

export const truckApi = createApi({
	reducerPath: "truckApi",
	baseQuery: dynamicBaseQuery,
	endpoints: (builder) => ({
		getAssets: builder.query<any, void>({
			query: () => ({
				url: "/asset",
				responseHandler: async (response: Response) => {
					const data = await response.json();

					// Generate a random Brazilian license plate (Mercosur format)
					const generateBrazilianPlate = () => {
						const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
						const numbers = "0123456789";

						// Format: ABC1D23
						const plate =
							letters[Math.floor(Math.random() * letters.length)] +
							letters[Math.floor(Math.random() * letters.length)] +
							letters[Math.floor(Math.random() * letters.length)] +
							numbers[Math.floor(Math.random() * numbers.length)] +
							letters[Math.floor(Math.random() * letters.length)] +
							numbers[Math.floor(Math.random() * numbers.length)] +
							numbers[Math.floor(Math.random() * numbers.length)];

						return plate;
					};

					// Add license plate to approximately 70% of assets
					const assetsWithPlates = data.map((asset: any) => {
						// 70% chance of having a license plate
						if (Math.random() < 0.5) {
							return {
								...asset,
								license_plate: generateBrazilianPlate(),
							};
						}
						return asset; // 30% of assets remain unchanged
					});

					return assetsWithPlates;
				},
			}),
		}),
		getTags: builder.query<any, void>({
			query: () => ({
				url: "https://api.tags.rio.cloud/tags",
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data.items;
				},
			}),
		}),
		getSummary: builder.mutation<any, void>({
			query: (requestPayload) => ({
				url: "/summary",
				method: "POST",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
	}),
});

export const { useGetAssetsQuery, useGetTagsQuery, useGetSummaryMutation } = truckApi;

import { Checkbox } from "@rio-cloud/rio-uikit";
import { ChangeEvent, memo, useMemo } from "react";
import { Virtuoso } from "react-virtuoso";
import AssetRow from "./AssetRow";

interface TagRowProps {
	tagId: string;
	tagName: string;
	assetsCount: number;
	isExpanded: boolean;
	isChecked: boolean;
	isIndeterminate: boolean;
	assets: { id: string; name: string; license_plate: string }[];
	isTagSelected: boolean;
	isAssetSelected: (assetId: string) => boolean;
	onTagSelect: (event: ChangeEvent<HTMLInputElement>) => void;
	onTagToggle: () => void;
	onAssetSelect: (assetId: string) => void;
}

const TagRow = memo(
	({
		tagId,
		tagName,
		assetsCount,
		isExpanded,
		isChecked,
		isIndeterminate,
		assets,
		isTagSelected,
		isAssetSelected,
		onTagSelect,
		onTagToggle,
		onAssetSelect,
	}: TagRowProps) => {
		const getRowHeight = (name: string, license_plate: string | null) => {
			const baseHeight = name.length > 33 ? 56 : 41;
			return license_plate ? baseHeight + 12.5 : baseHeight;
		};

		const totalHeight = useMemo(() => {
			return assets.reduce((acc: number, asset: { name: string; license_plate: string | null }) => {
				return acc + getRowHeight(asset.name, asset.license_plate);
			}, 0);
		}, [assets]);

		return (
			<div
				key={tagId}
				style={{ height: isExpanded ? undefined : "41px" }}
				className={`TreeNodeContainer user-select-none overflow-hidden ${isExpanded ? "open" : ""}`}
			>
				<div className="TreeNode from-group" onClick={onTagToggle}>
					<Checkbox
						className="TreeCheckbox"
						checked={isChecked}
						indeterminate={isIndeterminate}
						onChange={(e) => {
							e.stopPropagation();
							onTagSelect(e);
						}}
					/>
					<div className="TreeLabel">
						<div className="TreeLabelName">
							<div className="TreeLabelNameText">
								<div className="TreeLabelNameTextHeadline">{tagName}</div>
								<div className="TreeLabelCount label margin-left-10 label-muted label-filled label-condensed">{assetsCount}</div>
							</div>
							<div
								onClick={onTagToggle}
								className={`TreeLabelExpander margin-inline-10 rioglyph text-size-12 ${isExpanded ? "rioglyph-chevron-up" : "rioglyph-chevron-down"}`}
							/>
						</div>
					</div>
				</div>
				{isExpanded && (
					<Virtuoso
						style={{ height: totalHeight }}
						totalCount={assets.length}
						overscan={100}
						data={assets}
						itemContent={(_, asset) => {
							return (
								<AssetRow
									key={asset.id}
									asset={asset}
									tagName={tagName}
									isTagSelected={isTagSelected}
									isAssetSelected={isAssetSelected}
									onAssetSelect={onAssetSelect}
								/>
							);
						}}
					/>
				)}
			</div>
		);
	},
);

export default TagRow;
